<template>
  <div>
    <infoBackground></infoBackground>
    <a-spin class="spin-box" :spinning="loading">
      <div class="pay-order">
        <div class="pay-title">提交订单成功</div>
        <div class="goods-info">
          <div class="goods-title">{{ goodsInfo?.title }}</div>
          <div class="goods-title goods-price-box">
            <span>{{ pay_success ? '支付金额' : '待支付' }}</span> ：
            <span class="goods-price">
              ￥{{ orderInfo?.payMoney }}
            </span>
          </div>
        </div>
        <div class="pay-box" v-if="!pay_success">
          <div class="pay-type">
            <div class="pay-type-item">
              <i class="icon zhb-icon"></i>
              <span>支付宝</span>
            </div>
            <div class="pay-type-item">
              <i class="icon wx-icon"></i>
              <span>微信支付</span>
            </div>
          </div>
          <a-spin class="spin-box" :spinning="isPaying">
            <div class="qrCode-box">
              <vue-qr class="qrCode" :text="payurl" :size="300"></vue-qr>
            </div>
          </a-spin>

          <div class="notice">
            <svg class="svg-icon icon-scan">
              <use xlink:href="#icon-saoma" />
            </svg>
            <span>请打开微信或支付宝扫描二维码支付</span>
          </div>
          <div class="return" @click="returnBack">暂不支付</div>
        </div>
        <div class="pay-box finish-box" v-if="pay_success">
          <i class="finish-icon"></i>
          <div class="finish-title">支付成功</div>
          <div class="finish-notice">
            恭喜您已经完成支付，订单
            <span class="waite-time">{{ waitingTime }}s</span>后将自动跳转
          </div>
          <a-button size="large" class="finish-btn" @click="returnBack" type="primary">立即学习</a-button>
        </div>
      </div>
      <!-- 页脚 -->
      <foot v-if="!loading"></foot>
    </a-spin>
  </div>
</template>

<script>
import navroot from "@/component/public/nav/nav.vue";
import foot from "@/component/public/foot/foot.vue";
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import infoBackground from "@/component/public/info-background/info-background.vue";

export default {
  name: 'payorder',
  components: {
    navroot, VueQr, foot, infoBackground
  },
  data() {
    return {
      orderGuid: '',
      goodsGuid: '',
      couponGuid: null,
      payurl: '',
      waitingTime: 10,

      orderInfo: null,
      goodsInfo: null,
      userInfo: null,
      intPay: null,

      loading: true,
      isPaying: false,
      pay_success: false,
      phoneMode: false
    }
  },
  mounted() {
    const { userInfo, phoneMode } = this.$store.state;
    this.userInfo = userInfo;
    this.phoneMode = phoneMode;

    this.findOrder();
  },
  methods: {
    //查找支付订单判断是否支付
    async findOrder() {
      this.orderGuid = this.$route.params.id;
      const res = await this.$api.payorder.findPayOrder({ pk_payorderGuid: this.orderGuid });
      this.loading = false
      if (res.statusCode != 200) return this.$message.error(res.error);

      const data = res.data;
      this.orderInfo = data;
      this.goodsGuid = data.fk_goodsGuid;
      this.goodsInfo = this.$store.state.storeBooks.find(item => item.pk_store_book_id == this.goodsGuid);

      if (this.orderInfo.isPaid) return this.payFinish();
      this.payOff();
    },
    //支付
    async payOff() {
      if (this.isPaying) return;
      this.isPaying = true;
      let orderInfo = this.orderInfo;
      let goodsInfo = this.goodsInfo;
      let returl = ``;
      if (this.phoneMode) returl = `https://lessonplay_view_page.lessonplan.cn/pay-finish/${this.goodsGuid}`;
      //需要支付
      if (goodsInfo.price > 0) {
        //获取支付地址
        let subRes = await this.$api.payorder.submitOrder({ pk_payorderGuid: orderInfo.pk_payorderGuid, price: orderInfo.payMoney, name: goodsInfo.name, returl });
        if (subRes.statusCode != 200) {
          this.isPaying = false;
          return this.$message.error(subRes.msg);
        }
        this.payurl = subRes.data;
        if (this.phoneMode) return window.location.href = this.payurl;

        //循环兼听支付结果
        this.intPay = setInterval(() => { this.listenPay() }, 1000);
      }
      else this.payFinish();
      this.isPaying = false;
    },
    //兼听支付状态
    async listenPay() {
      let res = await this.$api.payorder.findPayOrder({ pk_payorderGuid: this.orderGuid });
      if (res.statusCode != 200) {
        clearInterval(this.intPay);
        return this.$message.error(res.error);
      }
      else if (res.data?.isPaid) {
        clearInterval(this.intPay);
        this.payFinish();
      }
    },
    //支付成功
    async payFinish() {
      this.pay_success = true;
      //购买成功，更新购买状态
      const { storeBooks } = this.$store.state;
      storeBooks.forEach((item) => {
        if (item.pk_store_book_id === this.goodsGuid) {
          item.isPaid = true;
        }
      })
      this.$store.commit("storeBooks", JSON.parse(JSON.stringify(storeBooks)));

      const that = this;
      const int = setInterval(() => {
        if (that.waitingTime <= 0) {
          clearInterval(int);
          that.returnBack();
        }
        that.waitingTime -= 1;
      }, 1000);
    },
    //返回
    returnBack() {
      this.$router.push(`/course-info/${this.goodsGuid}`);
    },
  },
  beforeUnmount() {
    if (this.intPay) clearInterval(this.intPay);
  }
}


</script>

<style scoped lang="scss">
@import "./pay-order.scss";
</style>