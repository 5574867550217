<template>
  <div class="foot-box">
    <div class="foot">
      <div class="area-box">
        <div class="logo-box">
          <div class="logo">
            <img class="logo-icon" src="https://img.lessonplan.cn/IMG/yuanxi/images/nav/nav-logo.png" alt />
          </div>
          <div class="more-text">探索更多你感兴趣的领域</div>
        </div>
        <div class="other-link">
          <div class="box-item" v-for="menu of menuList">
            <div class="title">{{ menu.title }}</div>
            <div class="menu-list">
              <div class="menu-item" v-for="item of menu.list" @click="chooseMenu(menu.type, item)">{{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="area-box">
        <div class="contact-us">
          <div class="title">手机应用（Android）</div>
          <vue-qr class="person-qrcode" text="https://electronserve.lessonplan.cn/%E5%85%83%E4%B9%A0.apk"
            :size="200"></vue-qr>
          <div class="notice">
            <div>请使用浏览器app直接扫码下载</div>
            <div>如果使用微信扫码，需要复制链接地址，打开其他浏览器app下载</div>
          </div>
        </div>
        <div class="contact-us">
          <div class="title">元习小程序</div>
          <img class="person-qrcode" src="https://img.lessonplan.cn/IMG/yuanxi/images/home/qrcode/元习小程序.jpg" alt />
        </div>
        <div class="contact-us">
          <div class="title">元习公众号</div>
          <img class="person-qrcode" src="https://img.lessonplan.cn/IMG/yuanxi/images/home/qrcode/元习.jpg" alt />
        </div>
        <div class="contact-us">
          <div class="title">联系我们</div>
          <img class="person-qrcode" src="https://img.lessonplan.cn/IMG/yuanxi/images/home/qrcode/person-qrcode.jpg"
            alt />
          <div class="phone">137 6516 8933</div>
          <div class="phone">wanglinrui@arktao.cn</div>
        </div>
      </div>
    </div>
    <!-- 公安备案 -->
    <div class="info-box">
      <div class="print canClick" @click="openCode('https://img.lessonplan.cn/IMG/Public/business-license.jpg')">
        营业执照</div>
      <div class="print canClick" @click="openCode('https://img.lessonplan.cn/IMG/Public/publication-license.jpg')">
        出版物经营许可证</div>
      <div class="print canClick" @click="openUrl('https://yuanxi.online/privacy-policy')">
        隐私声明</div>
      <div class="police-box canClick" target="_blank"
        @click="openUrl('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52010302003322')">
        <!-- <img :src="'/assets/images/public_security.png'" /> -->
        贵公网安备 52010302003322号
      </div>
      <div class="copyright">Copyright © 2023 舟道教育科技 All Rights Reserved</div>
    </div>

    <div class="popup-box" v-if="qrcode">
      <div class="blackscreen" @click="qrcode = ''"></div>
      <div class="box-content">
        <img :src="qrcode" />
      </div>
    </div>
  </div>
</template>
<script>
import VueQr from 'vue-qr/src/packages/vue-qr.vue';

export default {
  name: 'foot',
  components: {
    VueQr
  },
  data() {
    return {
      menuList: [
        {
          title: '舟道教育',
          type: 'link',
          list: [
            { name: '舟道官网', url: 'https://www.arktao.com' },
            { name: '超级教案', url: 'https://www.lessonplan.cn' },
            { name: '学习管理平台', url: 'https://inner-training.lessonplan.cn' }
          ]
        }, {
          title: '公众号',
          type: 'qrcode',
          list: [
            { name: '超级教案' },
            { name: '舟知' },
            { name: '双创思维' }
          ]
        },
      ],


      qrcode: ''
    }
  },
  mounted() {
    //监听store
    // this.$store.subscribe((res) => {
    //   if (!res) return;
    //   let { type, payload } = res;
    // })
  },
  methods: {
    //选择菜单
    chooseMenu(type, item) {
      if (type == 'link') window.open(item.url);
      else this.qrcode = `https://img.lessonplan.cn/IMG/yuanxi/images/home/qrcode/${item.name}.jpg`;
    },
    //打开出版物经营许可证
    openCode(url) {
      this.qrcode = url;
    },
    //打开链接
    openUrl(url) {
      window.open(url);
    }
  }
}
</script>
<style scoped lang="scss">
@import "./foot.scss";
</style>