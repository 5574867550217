<template>
  <div class="popup-box">
    <div class="blackscreen" @click="closeBox()"></div>
    <div class="box-content downIn">
      <svg class="svg-icon popup-close" @click="closeBox()">
        <use xlink:href="#icon-guanbi" />
      </svg>
      <div class="guide-item">
        <div class="guide-text">手机应用（Android）</div>
        <div class="qr-box">
          <vue-qr class="person-qrcode" text="https://electronserve.lessonplan.cn/%E5%85%83%E4%B9%A0.apk"
            :size="200"></vue-qr>
        </div>
        <div class="notice">
          <div>请使用浏览器app直接扫码下载</div>
          <div>如果使用微信扫码，需要复制链接地址，打开其他浏览器app下载</div>
        </div>
      </div>
      <div class="guide-item">
        <div class="guide-text">微信小程序</div>
        <div class="qr-box">
          <img class="person-qrcode" src="https://img.lessonplan.cn/IMG/yuanxi/images/home/qrcode/元习小程序.jpg" alt />
        </div>
      </div>
      <div class="guide-item">
        <div class="guide-text">微信公众号</div>
        <div class="qr-box">
          <img class="person-qrcode" src="https://img.lessonplan.cn/IMG/yuanxi/images/home/qrcode/元习.jpg" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueQr from 'vue-qr/src/packages/vue-qr.vue';

export default {
  name: "phoneGuide",
  components: {
    VueQr
  },
  data() {
    return {

    }
  },
  async mounted() {

  },
  methods: {
    //关闭窗口
    closeBox() {
      this.$emit('closeBox', true)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./phone-guide.scss";
</style>