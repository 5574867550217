<template>
    <div class="privacy-policy">
        <div class="content">
            <h1>《隐私政策》</h1>
            <p>欢迎使用我们的网站https://yuanxi.online/。本隐私政策旨在向您说明我们在您使用本网站服务过程中如何收集、使用、存储和共享您的个人信息，以及您对这些信息享有的权利。</p>

            <h2>一、我们收集的信息</h2>
            <p>1. 当您注册账户时，我们会收集您提供的姓名、邮箱地址、密码等信息，以便为您创建账户并提供个性化服务。</p>
            <p>2. 在您浏览网站过程中，我们的服务器可能会自动记录一些信息，例如您的IP地址、浏览器类型、访问时间、浏览的页面等，这些信息有助于我们优化网站性能和用户体验。</p>
            <p>3. 如果您使用网站的搜索功能，我们会收集您输入的关键词，以改进搜索结果的准确性。</p>

            <h2>二、信息的使用</h2>
            <p>1. 我们使用收集到的个人信息来为您提供和维护网站服务，包括但不限于账户管理、个性化内容推荐、客户支持等。</p>
            <p>2. 我们可能会将收集到的信息用于分析和研究，以改善网站的功能和服务，了解用户的使用习惯和需求，从而提供更符合您期望的内容。</p>
            <p>3. 在必要时，我们可能会使用您的信息与您进行沟通，例如通知您有关网站的重要更新、服务变更或处理您的反馈和投诉。</p>

            <h2>三、信息的共享</h2>
            <p>1. 我们不会将您的个人信息出售给第三方。但在以下情况下，我们可能会与第三方共享您的信息：</p>
            <p> - 获得您的明确同意。</p>
            <p> - 与我们的合作伙伴（如服务提供商）共享，以帮助我们提供网站服务，这些合作伙伴仅能在履行其职责所需的范围内使用您的信息，并受保密协议约束。</p>
            <p> - 根据法律法规的要求或响应法律程序，我们可能需要向相关政府部门或执法机构提供您的信息。</p>

            <h2>四、信息的存储和安全</h2>
            <p>1. 我们会将您的个人信息存储在安全的服务器上，并采取合理的技术和组织措施来保护您的信息免受未经授权的访问、使用、修改或泄露。</p>
            <p>2. 我们会定期评估和更新我们的安全措施，以适应不断变化的技术和安全环境。尽管我们努力保护您的信息安全，但请注意，互联网并非绝对安全的环境，我们无法保证信息传输的绝对安全性。</p>

            <h2>五、您的权利</h2>
            <p>1. 您有权访问、更正或删除您在我们网站上的个人信息。如果您需要行使这些权利，请联系我们的客服团队。</p>
            <p>2. 您有权拒绝我们将您的信息用于某些特定目的，例如个性化广告推荐。您可以在网站的设置中进行相应的调整。</p>
            <p>3. 如果您对我们的隐私政策或信息处理行为有任何疑问、意见或投诉，欢迎随时与我们联系，我们将尽快予以回复和处理。</p>

            <h2>六、政策的更新</h2>
            <p>我们可能会根据法律法规的变化、业务发展或其他原因对本隐私政策进行更新。更新后的政策将在网站上公布，我们建议您定期查看本页面以了解最新的隐私政策。</p>
        </div>
    </div>
</template>
<script setup>
import { onMounted, } from 'vue'


onMounted(() => {

})

</script>

<style lang="scss" scoped>
.privacy-policy {
    height: calc(100% - 44px);
    margin-top: 44px;
    line-height: 1.6;
    padding: 40px;
    overflow-y: auto;
    font-size: 16px;

    .content {
        max-width: 1300px;
        margin: 0 auto;

        h1,
        h2 {
            color: #ffffff;
        }

        p {
            color: #eeeeee;
        }
    }
}
</style>