<template>
  <div class="no-image">
    <infoBackground></infoBackground>
    <a-spin class="spin-box" :spinning="loading">
      <div class="order" v-if="!loading && book">
        <div class="pay-title">购买信息</div>
        <div class="goods">
          <div class="goods-show table-head">
            <div class="goods-name">数字教材</div>
            <div>价格</div>
            <div>有效期</div>
            <div>实付</div>
          </div>
          <div class="goods-show">
            <div class="goods-name">
              <i class="i-icon goods-cover"
                :style="{ backgroundImage: 'url(' + book.extension_info?.cover + '!260x390)' }"></i>
              <div class="goods-info">
                <div>{{ book.name }}</div>
                <!-- <div>{{ book.unitCount }}个章节</div> -->
              </div>
            </div>
            <div class="goods-price" v-if="book.is_unique_book">内测版本</div>
            <div class="goods-price" v-else>￥{{ book.price }}</div>

            <div class="goods-date">永久有效</div>
            <div class="goods-real-price" v-if="book.is_unique_book">激活码激活</div>
            <div class="goods-real-price" v-else>￥{{ book.realPrice }}</div>
          </div>
        </div>
        <!-- 支付方式 -->
        <div class="pay-model">
          <div class="pay-coupon">
            <!-- 优惠券 -->
            <div class="coupon">
              <p>优惠劵</p>
              <a-select class="coupon-select" size="large" ref="select" v-model:value="couponGuid"
                @change="handleChange" placeholder="选择优惠券" allowClear>
                <a-select-option :value="item.pk_couponGuid" v-for="item in couponList">
                  {{ item.couponTypeName }}
                </a-select-option>
              </a-select>
            </div>
            <div class="coupon">
              <p>激活码</p>
              <a-input-group compact>
                <a-input class="coupon-input" v-model:value="activeCode" placeholder="输入激活码验证" />
                <a-button class="coupon-button" type="primary" :loading="isVerifying"
                  @click="couponChecking">验证</a-button>
              </a-input-group>
            </div>
          </div>

          <div class="pay-count" :class="{ hideCount: book.is_unique_book }">
            <div class="count" v-if="!book.is_unique_book">
              <div class="all">
                <p>总价：</p>
                <span>￥{{ book.price }}</span>
              </div>
              <div class="coupon" v-if="reducePrice">
                <p>优惠：</p>
                <span>- ￥{{ reducePrice }}</span>
              </div>
              <div class="discount-info" v-if="limitedTimeDiscount">
                <p class="discount-rate">限时{{ limitedTimeDiscount.discount }}折优惠</p>
                <span class="countdown">{{ limitedTimeDiscount.remainTime }}</span>
              </div>
              <div class="underline"></div>
              <div class="amount">
                <p>需付金额：</p>
                <span class="oldPrice" v-if="book.realPrice !== book.price">￥{{ book.price }}</span>
                <span>￥{{ book.realPrice }}</span>
              </div>
            </div>
            <a-button class="pay-btn" @click="prepareNewOrder" type="primary" :loading="isPaying || isVerifying">立即{{
              book.is_unique_book ?
                '激活' : '购买' }}</a-button>
            <div class="notice">注：数字教材支付激活成功后，不支持退款</div>
          </div>
        </div>
      </div>
      <!-- 页脚 -->
      <foot v-if="!loading"></foot>
    </a-spin>
  </div>
</template>

<script>
import navroot from "@/component/public/nav/nav.vue";
import foot from "@/component/public/foot/foot.vue";
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import infoBackground from "@/component/public/info-background/info-background.vue";
import moment from 'moment';

export default {
  name: 'payment',
  components: {
    navroot, infoBackground,
    VueQr, foot
  },
  data() {
    return {
      goodsGuid: '',
      system_id: '',

      couponGuid: null,
      activeCode: null,

      book: null,
      user: null,
      chooseCoupon: null,
      intPay: null,
      currentStore: null,
      couponChecked: false,

      select_style: {
        "background-color": "#d6defa4a",
      },

      couponList: [],
      allStoreBooks: [],

      loading: true,
      isPaying: false,
      isVerifying: false,

      reducePrice: 0,
      limitedTimeDiscount: null,
      interDiscountTime: null,
    }
  },
  mounted() {
    this.goodsGuid = this.$route.params.id;

    const state = this.$store.state;
    this.user = state.userInfo;
    this.allStoreBooks = state.storeBooks;
    this.currentStore = state.currentStore;

    this.getGoods();
    this.setDiscount();
  },
  methods: {
    //获取数字教材详情
    async getGoods() {
      const goods = this.allStoreBooks.find(item => item.pk_store_book_id == this.goodsGuid);
      goods.realPrice = goods.price;
      this.book = goods;
      this.system_id = goods.system_id;

      this.findOrder();
    },

    //查找支付订单判断是否支付
    async findOrder() {
      const res = await this.$api.payorder.findPayOrder({ system_id: this.system_id, fk_userGuid: this.user.PK_UserGuid });
      if (res.statusCode != 200) return this.$message.error(res.error);
      if (res.data?.isPaid) return this.$router.push(`/payorder/${res.data.pk_payorderGuid}`);
      this.loading = false;

      this.getCouponList();
    },

    //获取优惠券
    async getCouponList() {
      const res = await this.$api.payorder.getCouponList({ fk_userGuid: this.user.PK_UserGuid, status: 1 });
      if (res.statusCode != 200) return;
      const data = res.data.filter(item => !item.deadline || (new Date(item.deadline) > new Date())) || [];
      this.couponList = data.filter(item => item.couponType !== "assign" && (item.couponConfigJson && item.couponConfigJson.system_id === this.system_id))
    },

    //选择优惠券
    handleChange() {
      const chooseCoupon = this.couponList.find(item => item.pk_couponGuid == this.couponGuid);
      this.chooseCoupon = chooseCoupon ? chooseCoupon : null;
    },

    //计算优惠后价格
    calculatePrice() {
      const { price } = this.book;
      let oldPrice = price;
      let realPrice = price

      //限时折扣
      if (this.limitedTimeDiscount) {
        const { discountPrice } = this.limitedTimeDiscount;
        realPrice = discountPrice;
        this.reducePrice = (price - discountPrice).toFixed(2);
      }

      //优惠券
      if (this.chooseCoupon) {
        const { couponType, couponDiscountValue } = this.chooseCoupon;
        if (couponType == "assign") {
          this.reducePrice = oldPrice;
          realPrice = 0;
        }
        else if (couponType.includes("discount")) {
          realPrice = Number((oldPrice * couponDiscountValue).toFixed(2));
          this.reducePrice = Number((oldPrice - realPrice).toFixed(2));
        }
      }

      if (realPrice < 0) realPrice = 0;
      this.book.realPrice = realPrice;
    },

    async prepareNewOrder() {
      if (this.isPaying) return;

      //验证激活码
      if (this.activeCode && !this.couponChecked) {
        await this.couponChecking();
      }
      if (this.chooseCoupon && this.book.realPrice > 0) {
        this.$nzconfirm("请注意！您使用了优惠券。如不立即支付，此优惠券后续将无法再次使用，确定要继续支付吗？", () => {
          this.newOrder();
        })
      }
      else {
        this.newOrder();
      }
    },

    //下订单
    async newOrder() {
      const { pk_store_id, fk_publishing_agency_id } = this.currentStore;
      const { fk_publishing_id, is_unique_book, realPrice } = this.book;

      if (is_unique_book && realPrice > 0) {
        return this.$message.warning("请使用激活码激活");
      }

      const chooseCoupon = this.chooseCoupon;
      const newOrder = {
        fk_goodsGuid: this.goodsGuid,
        fk_userGuid: this.user.PK_UserGuid,
        fk_publishing_id,
        fk_store_id: pk_store_id,
        fk_publishing_agency_id: fk_publishing_agency_id,
        system_id: this.system_id,
        type: "textbook",
        pk_couponGuid: chooseCoupon ? chooseCoupon.pk_couponGuid : ""
      }
      this.isPaying = true;
      const res = await this.$api.payorder.storeBookPayOrder(newOrder);
      if (res.statusCode != 200) {
        this.isPaying = false;
        return this.$message.error(res.message);
      }

      setTimeout(() => {
        this.$router.push(`/payorder/${res.data.pk_payorderGuid}`);
        this.isPaying = false;
      }, 1000);
    },

    //验证优惠券
    async couponChecking() {
      let activeCode = this.activeCode;
      if (!activeCode) return this.$message.warn("请先输入激活码");
      activeCode = activeCode.trim();

      if (this.isVerifying) return;
      this.isVerifying = true;

      const res = await this.$api.payorder.checkCode({ activation_code: activeCode, fk_goodsGuid: this.goodsGuid });
      this.isVerifying = false;

      if (res.statusCode != 200) return this.$message.error(res.message);
      const chooseCoupon = res.data;
      if (chooseCoupon.couponType !== "assign" && this.book.is_unique_book) {
        this.activeCode = "";
        return this.$message.warn(`很抱歉，此商品只能用“指定商品券”激活`);
      }

      this.chooseCoupon = chooseCoupon;
      this.$message.success("恭喜您，已获得优惠");
      this.couponChecked = true;
      this.calculatePrice();
    },
    async setDiscount() {
      const { pk_store_book_id, limited_time_discount, price } = this.book;
      if (!limited_time_discount) return;
      const { discount } = limited_time_discount

      const remainSeconds = await this.$api.bookManage.storeBookDiscountTime(pk_store_book_id).then(res => res.data);
      if (remainSeconds > 0) {
        this.limitedTimeDiscount = {
          discount,
          discountPrice: (price * (discount / 10)).toFixed(2),
          remainSeconds,
          remainTime: `00:00:00`
        }
        this.limitedTimeDiscount.remainTime = this.setRemainTime();
        this.calculatePrice();

        this.interDiscountTime = setInterval(() => {
          this.limitedTimeDiscount.remainSeconds--;
          this.limitedTimeDiscount.remainTime = this.setRemainTime();
        }, 1000)

      }
    },

    setRemainTime() {
      const { remainSeconds } = this.limitedTimeDiscount;
      if (remainSeconds <= 0) {
        this.limitedTimeDiscount = null;
        this.calculatePrice();
        clearInterval(this.interDiscountTime);
        return '00:00:00';
      }

      const duration = moment.duration(remainSeconds, 'seconds');
      const minutes = duration.minutes().toString().padStart(2, '0');
      const seconds = duration.seconds().toString().padStart(2, '0');
      const totalHours = (duration.days() * 24 + duration.hours()).toString().padStart(2, '0');

      return `${totalHours}:${minutes}:${seconds}`;
    },
  },
  beforeUnmount() {
    if (this.interDiscountTime) clearInterval(this.interDiscountTime);
  }
}


</script>

<style scoped lang="scss">
@import "./payment.scss";
</style>