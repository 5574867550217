<template>
    <div class="popup-box">
        <div class="blackscreen" @click="close"></div>
        <div class="box-content downIn">
            <svg class="icon-svg popup-close" @click="close">
                <use xlink:href="#icon-guanbi" />
            </svg>
            <iframe class="iframe-box" src="https://lessonplay_view_page.lessonplan.cn/help?currentNav=536"
                frameborder="0" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
        </div>
    </div>
</template>
<script setup>
import { onMounted } from 'vue'

const props = defineProps({
    payorder: Object,
})
const emit = defineEmits(['close']);

onMounted(() => {})

function close() {
    emit('close')
}

</script>

<style lang="scss" scoped>
.popup-box {
    .box-content {
        width: 1200px;
        height: 800px;
        border-radius: 20px;
        padding: 0;
        display: flex;
        background: #fff;

        .popup-close {
            font-size: 30px;
            color: #333;
        }

        .iframe-box {
            width: 100%;
            height: 100%;
        }
    }
}
</style>